// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.woff2?t=1693216011754", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("iconfont.woff?t=1693216011754", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("iconfont.ttf?t=1693216011754", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:iconfont-oa;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.iconfont-oa{font-family:iconfont-oa!important;font-size:16px;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-default:before{content:\"\\e654\"}.icon-singleLineText:before{content:\"\\e64c\"}.icon-pickDropPersons:before{content:\"\\e64d\"}.icon-attachmentUpload:before{content:\"\\e64e\"}.icon-applicant:before{content:\"\\e64f\"}.icon-radio:before{content:\"\\e650\"}.icon-phoneNumber:before{content:\"\\e651\"}.icon-personnelInput:before{content:\"\\e652\"}.icon-number:before{content:\"\\e653\"}.icon-descriptionText:before{content:\"\\e643\"}.icon-approvalFormSelect:before{content:\"\\e644\"}.icon-multipleSelect:before{content:\"\\e645\"}.icon-entryExitSelect:before{content:\"\\e646\"}.icon-IDCard:before{content:\"\\e647\"}.icon-address:before{content:\"\\e648\"}.icon-upload:before{content:\"\\e649\"}.icon-dateTime:before{content:\"\\e64a\"}.icon-multiLineText:before{content:\"\\e64b\"}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
